import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $nav = $('.header-nav__cta-link');
    $nav.each((i, el) => {
        const $this = $(el);
        const content = $this.html();
        if (content) {
            $this.html(content.replace('Get ', '<span class="d-none d-md-inline">Get </span>'));
        }
    });

    $('.button:not(input)').wrapInner('<span></span>');
});
